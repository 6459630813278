#block-system-main-menu {
  @include xssm {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    background: $black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translateY(-100%);
    transition: all ease-in .4s;
    z-index: -1;

    .open & {
      transform: translateY(0);
    }
  }

  .menu {
    display: flex;

    @include xssm {
      display: block;
      margin-top: -61px;
    }

    li {
      @include rem(margin, 0 0 0 30px);
      list-style: none;
      padding: 0;

      @include xssm {
        @include rem(margin, 20px 50px);
      }
    }

    a {
      color: $white;

      @include xssm {
        font-size: 30px;
        font-weight: 900;
      }

      &:hover,
      &:focus,
      &.active {
        color: $primary-color;
      }

      &.active {
        font-weight: bold;
      }
    }
  }

  #block-locale-language {
    @include rem(margin, 40px 50px);
    position: relative;
    bottom: auto;
    left: auto;
  }

  .copyright {
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;

    .container {
      display: block;
    }

    #block-views-contact-block-2 {
      position: relative;
      text-align: center;

      .views-field {
        display: inline-block;

        a {
          width: 40px;
        }
      }
    }
  }
}

.mobile-menu {
  display:none;
  width: 60px;
  height: 60px;
  text-align: center;
  z-index: 384;

  @include xssm {
    display: block;
  }

  span {
    position:relative;
    display:block;
    margin: 28px 13px;

    &:after,
    &:before {
      content:"";
      position:absolute;
      left: 0;
    }
  }

  span,
  span:after,
  span:before {
    height:3px;
    width:27px;
    text-indent:-9999px;
    background-color: $white;
    transition:all .25s ease-out;
    border-radius: 3px;
  }

  span:before {
    top:-8px;
  }

  span:after {
    top:8px;
  }
}

.open {
  .mobile-menu{
    border:none;

    span:before,
    span:after{
      top:0;
    }

    span {
      background-color: rgba(0,0,0,0);

      &:after{
        transform: translateY(0) rotate(-45deg);
      }

      &:before{
        transform: translateY(0) rotate(45deg);
      }
    }
  }
}

#block-locale-language {
  position: fixed;
  bottom: 20px;
  left: 5%;

  ul.language-switcher-locale-url {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  li {
    list-style: none;
    padding: 0;
    line-height: 1;
    order: 3;

    a {
      color: $gray;
      text-transform: uppercase;
    }

    &.active {
      order: 1;

      a {
        color: $white;
      }
    }
  }
}

@keyframes shake {
  0% {
    // margin-left: 0px;
    margin-left: 50px;
  }
  // 70% {
  //   margin-left: -70px;
  // }
  90% {
    margin-left: 0px;
  }
}

#block-menu-menu-tags {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include xssm {
    height: auto;
    display: block;
  }

  &.no-anim {
    > .content > .menu {
      @include xssm {
        perspective: none;
        transform: none;
      }
    }
  }

  > .content > .menu {
    padding: 0;
    margin: 0;

    @include xssm {
      overflow-x: scroll;
      white-space: nowrap;
      animation: shake 1s cubic-bezier(.97,.10,.10,.97) both;
      animation-delay: 1s;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }

    li {
      list-style: none;
      padding: 0;
      margin: 0;

      @include xssm {
        display: inline-block;
      }
    }

    a {
      @include rem(padding, 20px 0);
      display: block;
      position: relative;
      text-align: center;

      @include xssm {
        @include rem(padding, 20px);
      }

      &:before {
        content: '';
        display: block;
        height: 1px;
        width: 0;
        transition: all ease-in .2s;
        background: $primary-color;
        position: absolute;
        left: 0;
        top: 50%;
      }

      &:hover,
      &:focus,
      &.active,
      &.active-trail {
        font-weight: bold;
        color: $white;

        &:before {
          width: 40px;

          @include xssm {
            display: none;
          }
        }

        @include xssm {
          font-weight: normal;
        }
      }
    }
  }

  > .content .expanded {

    .menu {
      @include rem(padding, 0 20px);
      background: $primary-color;
      display: none;
      width: calc(100% - 40px);

      @include xssm {
        position: fixed;
        left: 0;
        top: 120px;
        overflow-x: scroll;
        max-width: calc(100% - 40px);
        display: none;
        z-index: 999;
      }

      a {
        color: $black;

        &:hover,
        &:focus {
          color: $black;
        }
      }
    }

    &.active-trail {

      @include mdlg {
        .menu {
          display: block;
        }
      }
    }

    &.active {
      @include xssm {
        position: relative;

        &:after {
          @include triangle(top, 25px, 10px, $primary-color);
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .menu {
        display: block;

        @include xssm {
          display: block;
        }
      }
    }
  }
}

.bef-select-as-radios {
  .submenu-wrapper {
    @include rem(padding, 0 20px);
    background: $primary-color;
    display: none;
    width: calc(100% - 40px);

    @include xssm {
      position: absolute;
      left: 0;
      top: 60px;
      overflow-x: scroll;
      max-width: calc(100% - 40px);
    }

    &.active {
      display: block;
    }

    label {
      color: $black;
    }
  }

  @include xssm {
    .active {
      &.has-submenu {
        position: relative;

        &:after {
          @include triangle(top, 25px, 10px, $primary-color);
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .active + .submenu-wrapper {
    display: block;
  }
}

.page-galerie,
.node-type-page-galerie {
  #block-system-main-menu {
    .menu {
      a.galerie {
        color: $primary-color;
        font-weight: bold;
      }
    }
  }
}

.node-type-stories {
  #block-system-main-menu {
    .menu {
      a.series {
        color: $primary-color;
        font-weight: bold;
      }
    }
  }
}
