*:focus,
*:active {
	outline: none;
	box-shadow: none;
}
html {
  height: 100%;
}

body {
	font-size: font-size(base);
	font-family: $font-family-base;
	line-height: $global-line-height;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.toolbar {
    min-height: calc(100% - 65px);
  }
}

#content-to-resize {
  flex: 1 1 auto;

	.page-services & {
		display: flex;
	  align-items: center;
	}

  .page-clients & {
    align-items: flex-start;
  }

  #main {
    width: 100%;
  }

  @include xssm {
    align-items: flex-start;
  }
}

h1 {
	@extend %headings-1-to-6;
  color: $white;
	font: {
		@include rem(size, font-size(h1));
    weight: 900;
	}
}

h2 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, font-size(h2));
	}
}

h3 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, font-size(h3));
	}
}

h4 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, font-size(h4));
	}
}

h5 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, font-size(h5));
	}
}

h6 {
	@extend %headings-1-to-6;
	font: {
		@include rem(size, font-size(h6));
	}
}

img {
	max-width: 100%;
	height: auto;
}

a {
	transition: all linear .3s;
	color: $white;
	text-decoration: none;

  &:hover,
	&:focus {
		color: $primary-color;
	}

  &.back {
    font-size: 0;
    color: $primary-color;
    display: block;
    margin-top: 50px;
    transition: all ease-in .3s;

    &:before {
      @include custom-font('\EA01');
      font-size: 10px;
    }

    &:hover,
    &:focus {
      transform: translateX(-20px);
    }
  }
}

ul {
  padding: 0;
  margin: 0;
}

img {
  display: block;
}

.onlymobile {
  display: none;

  @include xssm {
    display: block;
  }
}

.onlydesktop {
  @include xssm {
    display: none;
  }
}

.ajax-progress {
  display: none;
}

#messages {
  position: fixed;
  bottom: 40px;
  width: 80%;
  max-width: 1120px;
  max-width: 70rem;

  @media (min-width: 1781px) {
    max-width: none;
  }
}

.page-node-478 {
  .field-name-body {
    padding-left: 0.9375rem;
    border-left: 1px solid #e3c55a;
    line-height: 1.8;
  }
}
