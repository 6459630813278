header {
  background: $black;
  box-shadow: 0 0 1px $white;
  position: fixed;
  z-index: 20;
  width: 100%;
  left: 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
  }

  .front & {
    background: none;
  }

  .container {
    width: calc(90% - 60px);
    max-width: none;
    margin-left: 5%;

    @include xssm {
      width: 100%;
      margin: 0;
      background: $black;
    }
  }

  @include xssmmd {
    @include rem(padding-right, 60px);
  }
}

.header__name-and-slogan {
  @include xs {
    flex: 1 1 auto;
  }
}

.header__logo {
  img {
    max-width: 290px;

    @include xssm {
      max-width: 100%;
      margin: auto;
    }
  }
}
