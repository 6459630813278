.node-type-contact {
  .field-name-field-tel,
  .field-name-field-email,
  .field-name-body {
    @include rem(padding, 0px 0 5px 15px);
    border-left: 1px solid $primary-color;

    p {
      margin: 0;
    }
  }

  .field-name-body {
    padding-bottom: 0;
  }

  .field-name-field-facebook,
  .field-name-field-linkedin,
  .field-name-field-instagram {
    @include rem(margin, 0 10px);
    display: inline-block;

    a {
      font-size: 0;

      &:before {
        font-size: 20px;
      }
    }
  }

  .field-name-field-facebook {
    a {
      &:before {
        @include custom-font('\EA04');
      }
    }
  }

  .field-name-field-linkedin {
    a {
      &:before {
        @include custom-font('\EA06');
      }
    }
  }

  .field-name-field-instagram {
    a {
      &:before {
        @include custom-font('\EA05');
      }
    }
  }

  .group-social {
    @include rem(margin, 40px auto 0);
    text-align: center;
  }
}

.b-lazy {
  opacity: 0;
  transition: all ease-in .4s;

  &.b-loaded {
    opacity: 1;
  }
}
