$enable-legacy-support-for-ie8: true;
$container-width: 1120px;
/* ------------------------
* COLORS
* -----------------------*/
$white: #fff;
//$gray-lighter: #f8f9fa;
$gray-light: #cac9c9;
$gray: #8f8b8b;
$gray-dark: #352f32;
//$gray-darker: #212529;
$black: #151213; //#161314

$primary-color: #e3c55a;
//$secondary-color: #fdc800;

//$success: #28a745;
$warning: #fd7e14;
$error: #ff0000;
//$alert: #6f42c1;
//$info: #17a2b8;

// Social
//
$facebook: #3b5998;
//$twitter: #4099ff;
//$vimeo: #1ab7ea;
//$dailymotion: #0066a6;
$linkedin: #0274b3;
//$youtube: #cc181e;
//$deezer: #1990db;
//$pinterest: #cb2027;
$instagram: #9f3da1;

/* ------------------------
* FONTS
* -----------------------*/
@import url('https://fonts.googleapis.com/css2?family=STIX+Two+Text:ital@1&family=Poppins:wght@300;400;600;700;800&display=swap');
$font-family-base: "Poppins", sans-serif;
$font-family-headings: "Poppins", sans-serif;
$font-family-alt: 'STIX Two Text', serif;

$global-font-size: 16px;
$global-line-height: 1.5;

$headings-color: $primary-color;
$headings-font-weight: 400;

// Headings

$font-size: (
  base: 14px,
  h1: 44px,
  h2: 28px,
  h3: 24px,
  h4: 18px,
  h5: 16px,
  h6: 16px,
  small: 12px,
  alt: 14px,
);

@function font-size($key) {
  @if map-has-key($font-size, $key) {
    @return map-get($font-size, $key);
  }

  @warn "Unknown `#{$key}` in $z-layers.";
  @return null;
}

/* -----------------------
* Mediaqueries
* ---------------------*/

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

$screen-xxs: 320px;
// Extra small screen / phone
// Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
// Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
// Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
// Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 700px;
$screen-sm-min: $screen-sm;
// Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
// Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
// Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
// Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1400px;
$screen-lg-min: $screen-lg;
// Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-xlg-min: 1781px;
