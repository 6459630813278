/* -------------------------------
 * CONFIG
 * ------------------------------*/
@import 'config/variables';
@import 'config/mixins';
@import 'config/placeholders';

/* -------------------------------
 * LIBRARIES
 * ------------------------------*/
//@import 'libraries/font-awesome/font-awesome';
@import 'libraries/font-icons';
@import 'libraries/slick/slick';
@import 'libraries/fullpage';
@import 'libraries/lightbox';

/* -------------------------------
 * BASE
 * ------------------------------*/
@import 'base/normalize';
@import 'base/grid';
@import 'base/typography';

/* -------------------------------
 * COMPONENTS
 * ------------------------------*/
@import 'components/blocks';
@import 'components/buttons';
@import 'components/fields';
@import 'components/header';
@import 'components/regions';
@import 'components/footer';
@import 'components/forms';
@import 'components/menus';
@import 'components/editor-menu';
@import 'components/login';

/* -------------------------------
 * THEME
 * ------------------------------*/
