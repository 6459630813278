input[type="text"],
input[type="email"],
textarea {
  @extend %form-item;
}

.form-actions {
  @include rem(margin-top, 40px);
  text-align: center;
}

.webform-client-form {
  @include rem(margin-top, 40px);
}

.webform-component--sujet,
.webform-component--subject {
  @include rem(padding, 0 15px);
  width: 100%;
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  background: {
    color: $white;
    image: none;
  }
  border: 1px solid $black;
  position: relative;

  &:after {
    @include custom-font('\EA08');
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -3px;
    color: $black;
    font-size: 7px;
    pointer-events: none;
  }

  select {
    @include rem(padding, 15px 0);
    background: none;
    border: none;
    width: 100%;
    appearance: none;
  }
}
