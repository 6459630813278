#content-to-resize .container {
  @extend %container;

  .node-type-home &,
  .node-type-page & {
    #main > & {
      display: flex;
      align-items: center;
    }
  }

  .page-services & {
    width: calc(80% - 60px);
    margin-left: 10%;
    max-width: none;

    @include xssm {
      width: 90%;
      margin-left: auto;
    }
  }

  // .front & {
  //   width: calc(90% - 60px);
  //   margin-left: 5%;
  //   max-width: none;
  // }
}

body {
  background: $black;
  color: $white;
}

#maincontent {
  margin-top: 60px;

  @include xssm {
    margin-top: 70px;
  }
}

.layout-3col__right-content {

  #maincontent {
    width: calc(80% - 20px - 60px); // Largeur - espace sidebar left - largeur sidebar right;
    margin-left: calc(20% + 20px);

    @include xssm {
      width: 100%;
      margin-left: 0;
      margin-top: 0;

      margin-top: 120px;
    }
  }
}

.layout-3col__right-sidebar {
  @include rem(padding-bottom, 20px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 1px $white;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(calc(100% - 60px));
  transition: all ease-in .3s;
  z-index: 30;

  @include xssm {
    display: none;
  }

  &.active {
    background: rgba(41, 36, 37, 0.8);
    transform: translateX(0);
  }

  .region {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    #block-webform-client-block-1,
    #block-views-contact-block-1,
    #block-webform-client-block-478 {
      flex: 1 1 auto;
    }
  }
}

.layout-3col__left-sidebar {
  width: 20%;
  margin-right: 20px;
  box-shadow: 0 0 1px $white;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  @include xssm {
    width: 100%;
    margin: 0;
    margin-top: 60px;
    box-shadow: none;
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;

      position: fixed;
      top: 0;
      z-index: 18;
      background: $black;
  }
}

.column {
  margin-right: 20px;

  @include lg {
    width: calc(33.3333% - 14px) !important;
  }

  @include smmd {
    width: calc(50% - 10px) !important;
  }

  @include xs {
    width: 100% !important;
    float: none !important;
  }

  &.last {
    margin-right: 0;
  }
}

.region-content {
  .node-type-stories & {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
    grid-auto-rows: 1px;

    @include lg {
      grid-template-columns: repeat(auto-fill, minmax(30%,1fr));
    }

    @include xs {
      display: block;
    }

    img {
      @include xs {
        margin-bottom: 20px;
      }
    }

    #block-system-main,
    #block-system-main .content,
    #block-system-main .node-stories,
    #block-system-main .field-name-field-images,
    #block-system-main .field-items {
      display: contents;
    }
  }
}

.cv-vert {
  #block-views-stories-block-1 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

.cv-hor {
  #block-views-stories-block-1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.view-galerie {
  .view-content {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(30%,1fr));
    grid-auto-rows: 1px;

    @include xs {
      display: block;
    }

    a {
      display: block;
    }
  }

  .item-grid {
    position: relative;

    .edit {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .delete {
      position: absolute;
      top: 10px;
      right: 10px;

      a {
        font-size: 0;
        &:before {
          @include custom-font('\EA11');
          font-size: 16px;
        }
      }
    }
  }
}
