/* -----------------------------
 * HEADING
 * ----------------------------*/
%headings-1-to-6 {
  color: $headings-color;
  font: {
    family: $font-family-headings;
    weight: $headings-font-weight;
  }
}

/* -----------------------------
 * GRID
 * ----------------------------*/
%container {
  width: 80%;
  @include rem(max-width, $container-width);
  margin: {
    right: auto;
    left: auto;
  }

  @include xlg {
    max-width: none;
  }

  @include xssm {
    width: 90%;
  }
}

/* -----------------------------
 * FORMS
 * ----------------------------*/
%form-item {
  @include rem(padding, 15px);
  box-sizing: border-box;
  appearance: none;
  display: block;
  width: 100%;
  border-radius: 4px;
  background: {
    image: none;
  }
  border: 1px solid $black;

  &:focus {
    outline: none;
    border-color: $gray;
  }

  &::placeholder {
    color: $gray-light;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: $gray;
    opacity: 1;
  }

  &:disabled {
    color: $gray;
    cursor: pointer;
  }

  &.error {
    border: {
      color: $error;
    }

    + .description {
      color: $error;
    }
  }
}

/* ---------------------------
 * BUTTONS
 * --------------------------*/

%button {
  @include rem(padding, 10px 20px);
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

%button-primary {
  background: $primary-color;
  color: $black;
  border-radius: 30px;
  text-transform: none;
  font-weight: 700;
  font-size: 12px;

  &:hover,
  &:focus {
    font-weight: 900;
  }
}

%button-secondary {
  background: $primary-color;
  color: $white;

  &:hover,
  &:focus {
    background: $primary-color;
  }
}

%button-warning {
  background: $warning;
  text-transform: uppercase;
  font-weight: 700;

  &:hover,
  &:focus {
    background: $warning;
  }
}

%button-outline {
  border: 1px solid $primary-color;
  color: $primary-color;
  background: none;
  border-radius: 30px;

  &:hover,
  &:focus {
    background: $primary-color;
    color: $white;
  }
}
