// ADMIN
@font-face {
		font-family: 'main';
		font-weight: normal;
		font-style: normal;
		src: url('../../seven_child/fonts/icofont.woff') format('woff'),
				 url('../../seven_child/fonts/icofont.woff2') format('woff2');
}
$white: #eff3f3;

$grey: lighten(#1c1519, 5%);
$dark-grey: #392b33;

$turquoise: #e4c55a;

$red: #ff6c60;
$dark-red: #cd3f3d;

ul.tabs.primary {
	position: fixed;
	bottom: 0;
	right: 50px;
	z-index: 300;
	display: flex;
	margin: 0;
	border:none;
	li {
		list-style: none;
	&:before {
		display: none;
	}
	a {
		background: $grey;
		padding: 0.5em 1em;
		 display: block;
		 margin: 0 1px;
		 color: #FFF;
		 text-decoration: none;
		 font-weight: 100;
		 border:none;
		&:hover,
		&:focus,
		&.active {
			border:none;
		  background: $turquoise;
      color: $dark-grey;
		}
	}
	}
}


#tools {
	background: $dark-grey;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 300;
	.region-tools {
		display: flex;
		justify-content: space-between;
	}
	.block-menu {
		width: 100%;
	}
	.menu {
		padding: 0;
		margin: 0;
		display: flex;
		li {
			list-style: none;
			padding: 0;
			margin: 0;
			position: relative;
			&.first {
				a {
					font-weight: bolder;
				}
			}
			&.last {
				flex: 1;
				text-align: right;

				a {
					display: inline-block;
					width: auto;
				}
			}
			a {
				display: block;
				width: 100%;
				box-sizing: border-box;
				color: $white;
				text-decoration: none;
				padding: 0 1.2em;
				text-transform: uppercase;
				font-family: $font-family-base;
				line-height: 65px;
				height: 65px;
				&:before {
					margin-right: 5px;
					font-family: FontAwesome;
				}
				&:hover,
				&:focus {
					background: $turquoise;
          color: $dark-grey;
				}
        &.icon {
          display: flex;
          align-items: center;

          &:before {
            @include rem(margin-right, 10px);
          }
        }

				&.icon-logout {
					background: $red;
          display: inline-block;

          &:before {
            content: "\EA04";
          	display: inline-block;
          	font-family: 'main';
          	-moz-osx-font-smoothing: grayscale;
          	-webkit-font-smoothing: antialiased;
          	font-style: normal;
          	font-variant: normal;
          	font-weight: normal;
          	line-height: 1;
          	text-transform: none;
          }

					&:hover,
					&:focus {
						background: $dark-red;
            color: $white;
					}
				}

        &.icon-add {

          &:before {
            content: "\EA01";
          	display: inline-block;
          	font-family: 'main';
          	-moz-osx-font-smoothing: grayscale;
          	-webkit-font-smoothing: antialiased;
          	font-style: normal;
          	font-variant: normal;
          	font-weight: normal;
          	line-height: 1;
          	text-transform: none;
          }
				}

        &.icon-listing {

          &:before {
            content: "\EA02";
          	display: inline-block;
          	font-family: 'main';
          	-moz-osx-font-smoothing: grayscale;
          	-webkit-font-smoothing: antialiased;
          	font-style: normal;
          	font-variant: normal;
          	font-weight: normal;
          	line-height: 1;
          	text-transform: none;
            @include rem(font-size, 12px);
          }
				}

        &.icon-order {

          &:before {
            content: "\EA05";
          	display: inline-block;
          	font-family: 'main';
          	-moz-osx-font-smoothing: grayscale;
          	-webkit-font-smoothing: antialiased;
          	font-style: normal;
          	font-variant: normal;
          	font-weight: normal;
          	line-height: 1;
          	text-transform: none;
          }
				}
        &.icon-edit {

          &:before {
            @include custom-font("\EA28");
          }
        }
				&.icon-home {
          @include rem(padding-left, 15px);
          font-size: 0;
          display: flex;
          align-items: center;

          &:before {
            content: "\EA03";
          	display: inline-block;
          	font-family: 'main';
          	-moz-osx-font-smoothing: grayscale;
          	-webkit-font-smoothing: antialiased;
          	font-style: normal;
          	font-variant: normal;
          	font-weight: normal;
          	line-height: 1;
          	text-transform: none;
            @include rem(font-size, 16px);
          }

          &:hover,
					&:focus {
						background: none;
						color: $turquoise;
					}
				}
			}
			.menu {
				display: none;
				position: absolute;
				left: 0;
				top: 100%;
				background: $grey;
				min-width: 320px;
				li {
		&.last {
						text-align: left;

						a {
							display: block;
							width: 100%;
						}
					}
					a{
						height: 40px;
						line-height: 40px;
						&:hover,
						&:focus {
							background: darken($turquoise, 10%);
						}
					}
					&.first {
						a{
							font-weight: normal;
						}
					}
				}
			}
			&:hover {
				> a {
					background: $turquoise;
				}
				.menu {
					display: block;
				}
			}
		}
	}
}
.logged-in {
	padding-top: 65px;
}
