#block-views-contact-block {
  @include rem(padding, 0 40px 0 70px);

  h2 {
    color: $white;
    font-weight: bold;
    margin-top: 0;
  }

  .views-field-body {
    border-left: 1px solid $primary-color;
    padding-left: 15px;

    p {
      margin: 0;
    }

    a {
      color: $white;

      &:hover,
      &:focus {
        color: $primary-color;
      }
    }
  }

  &-1 {
    .views-field-field-espace-membre {
      a {
        height: 60px;
        vertical-align: middle;
        font-size: 0;
        display: inline-block;
        width: 60px;
        text-align: center;

        &:before {
          @include custom-font('\EA07');
          font-size: 26px;
          line-height: 60px;
        }
      }
    }

    .views-field-nothing {
      position: absolute;
      right: 0;
      top: 0;

      span {
        height: 60px;
        vertical-align: middle;
        display: inline-block;
        width: 60px;
        text-align: center;
        line-height: 60px;

        i {
          vertical-align: middle;
          color: $primary-color;
        }
      }
    }
  }

  &-2 {
    position: absolute;
    left: 0;
    bottom: 20px;

    .views-field {
      a {
        @include rem(margin-top, 15px);
        font-size: 0;
        display: inline-block;
        width: 60px;
        text-align: center;
        vertical-align: middle;

        &:before {
          font-size: 20px;
        }
      }
    }

    .views-field-field-facebook {
      a {
        &:before {
          @include custom-font('\EA04');
        }
      }
    }

    .views-field-field-instagram {
      a {
        &:before {
          @include custom-font('\EA05');
        }
      }
    }

    .views-field-field-linkedin {
      a {
        &:before {
          @include custom-font('\EA06');
        }
      }
    }
  }

  &-3 {
    transform: rotate(-90deg) translateX(-50%);
    position: absolute;
    left: 0;
    top: 50%;
    transform-origin: top left;
    height: 60px;
    line-height: 60px;

    .field-content {
      cursor: pointer;
      transition: all ease-in .3s;

      &:before {
        @include custom-font('\EA03');
        @include rem(margin-right, 10px);
        font-size: 14px;
        vertical-align: middle;
      }

      .active &,
      &:hover {
        color: $primary-color;
      }
    }
  }
}

#block-webform-client-block-1,
#block-webform-client-block-478 {
  @include rem(padding, 0 40px 0 70px);
}

.copyright {
  @include rem(padding, 0 40px 0 70px);

  #maincontent & {
    @include rem(padding, 20px 0);
  }

  p {
    @include rem(font-size, 10px);
    margin: 0;
    text-align: center;

    @include xssm {
      @include rem(font-size, 11px);
    }

    &:first-child {
      font-weight: bold;
    }
  }
}

.region-transition {
  max-width: 100%;
}

#block-views-transition-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  @include xssm {
    top: 30px;
    width: 100%;
    right: auto;
    overflow: hidden;
  }

  .content,
  .view-transition,
  .view-transition > .view-content,
  .views-row,
  .views-field,
  .field-content,
  .item-list,
  ul,
  li {
    height: 100%;
    width: 100%;
  }

  .item-list {
    transform: translateX(0);
    transition: all ease-in .3s;

    ul {
      margin: 0;
      padding: 0;
      position: relative;
    }

    li {
      list-style: none;
      padding: 0;
      margin: 0;
      position: absolute;
      display: none;
      text-align: center;
      align-items: flex-end;
      width: 100%;

      &:first-child {
        display: flex;
      }
    }
  }

  img {
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    transform: translateX(calc( -50% + 200px));
    width: auto;

    @include xssm {
      left: 50%;
      transform: translateX(-50%);
      height: 70%;
      max-width: none;
    }
  }

  .ajax-loaded & {
    .item-list {
      transform: translateX(-35%);

      @include xssm {
        transform: translateX(-75%);
      }
    }
  }

  .page-node-4 & {
    .item-list {
      transform: translateX(-35%);

      @include xssm {
        transform: translateX(-75%);
      }

      li {
        display: flex;
      }
    }
  }

  .accueil & {
    .item-list {
      transform: translateX(0);

      @include xssm {
        transform: translateX(0);
      }
    }
  }
}

#block-views-intro-block {
  p {
    margin: 0;
  }

  .txt-rotate {
    display: block;
    font-size: 35px;
    font-weight: bold;
    height: 50px;
    text-transform: capitalize;

    @include xs {
      font-size: 28px;
      height: 45px;
    }

    > .wrap {
      border-right: 2px solid $primary-color;
    }
  }
}

.view-services {
  margin: auto;
  text-align: center;

  .view-content {
    display: flex;
    justify-content: space-between;

    @include xssm {
      flex-wrap: wrap;
    }
  }

  .views-row {
    width: 23%;
    display: flex;
    flex-direction: column;

    @include sm {
      width: 48%;
      margin-bottom: 4%;
    }

    @include xs {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  .content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .field-name-body {
    flex: 1 1 auto;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
  }

  h2 {
    @include rem(font-size, 18px);
    font-weight: 900;
    text-align: center;
    display: flex;
    align-items: flex-end;
    margin-bottom: 0;

    &:before,
    &:after {
      content: '';
      display: inline-block;
      height: 13px;
      flex: 1 1 auto;
      border-top: 1px solid $primary-color;
    }

    &:before {
      border-left: 1px solid $primary-color;
      margin-right: 10px;
    }

    &:after {
      border-right: 1px solid $primary-color;
      margin-left: 10px;
    }
  }

  .content {
    border-left: 1px solid $primary-color;
    border-right: 1px solid $primary-color;
    border-bottom: 1px solid $primary-color;
    padding: 20px;

    p {
      margin: 0;
      font-size: 12px;
    }
  }

  .field-name-field-sous-titre {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 25px;
  }

  // .view-content {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  // }

  // .views-row {
  //   border: 2px solid $primary-color;
  //   flex: 0 0 calc(47% - 4px);
  //   margin-bottom: 7%;
  //   perspective: 500px;
  //   width: calc(47% - 4px);
  //
  //   @include xssm {
  //     flex: 0 0 100%;
  //     width: 100%;
  //     margin-bottom: 25px;
  //   }
  //
  //   &:nth-child(2n) {
  //     border: none;
  //     flex: 0 0 47%;
  //     width: 47%;
  //
  //     @include xssm {
  //       flex: 0 0 100%;
  //       width: 100%;
  //     }
  //   }
  //
  //   .card {
  //     width: 100%;
  //     height: 100%;
  //     position: relative;
  //     perspective: 1000px;
  //     transform-style: preserve-3d;
  //     transition: all 0.4s ease-in-out;
  //
  //     &:before {
  //       content:"";
  //       display: block;
  //       padding-top: 100%;
  //     }
  //   }
  //
  //   &:hover {
  //     .card {
  //       transform: rotateY(180deg);;
  //     }
  //   }
  // }

  // h2 {
  //   @include rem(font-size, 18px);
  //   color: $white;
  //   font-weight: bold;
  //   margin: 0;
  // }

  // .content-front,
  // .field-name-body {
  //   position: absolute;
  //   top: 0;
  //   height: 100%;
  //   width: 100%;
  //   backface-visibility: hidden;
  //   transform-style: preserve-3d;
  //   transition: transform .7s cubic-bezier(.4,.2,.2,1);
  //   backface-visibility: hidden;
  // }

  // .inside {
  //   @include rem(padding, 20px);
  //   background: rgba($black, 0.5);
  //   display: flex;
  //   flex-direction: column;
  //   height: calc(100% - 40px);
  //   justify-content: center;
  //   text-align: center;
  // }

  // .field-name-field-sous-titre {
  //   &:after {
  //     @include rem(font-size, 32px);
  //     @include rem(margin-top, 15px);
  //     content:"+";
  //     color:$primary-color;
  //     display: block;
  //     font-weight: bolder;
  //   }
  // }

  // .field-name-body {
  //   transform: rotateY( 180deg );
  //   background: $white;
  //   color: $black;
  //
  //   .field-items {
  //     @include rem(padding, 20px);
  //     @include rem(font-size, 14px);
  //     display: flex;
  //     flex-direction: column;
  //     height: calc(100% - 40px);
  //     justify-content: center;
  //     text-align: center;
  //     color: $gray;
  //
  //     strong {
  //       @include rem(font-size, 16px);
  //     }
  //   }
  // }
}

@keyframes bascule {
  0% {
    opacity: 0;
    transform: translate(-50%, -80px);
  }
  70% {
    transform: translate(-50%, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
}

.view-temoignages.view-display-id-page,
.view-temoignages.view-display-id-block_2 {
  margin: 0 auto;
  width: 90%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include xs {
    height: auto;
    width: 80%;
  }

  .icon-arrow-bottom {
    font-size: 24px;
    display: block;
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
    opacity: 0;
    animation: bascule 1s .8s forwards;
    animation-delay: 2s;

    @include xssm {
      display: none;
    }

    &:before {
      @include custom-font('\EA08');
    }
  }

  .slick-next,
  .slick-prev {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    z-index: 100;
    line-height: 40px;
    text-align: center;
    opacity: 0.3;

    &:before {
      vertical-align: middle;
      font-size: 25px;
    }

    &:hover,
    &:focus {
      opacity: 1;

      &:before {
        color: $primary-color;
      }
    }
  }

  .slick-next{
    right: -40px;

    @include lg {
      right: calc(-5% + 60px);
    }

    &:before {
      @include custom-font('\EA10');
    }
  }

  .slick-prev{
    left: -40px;

    @include lg {
      left: -5%;
    }

    &:before {
      @include custom-font('\EA09');
    }
  }

  .slick-slide {
    opacity: 0.2;
    text-align: center;
    transform: scale(0.75);
    transform-origin: center center;
    transition: all ease-in .2s;

    &.slick-center {
      opacity: 1;
      transform: scale(1);
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-dots {
    @include rem(margin-top, 40px);
    text-align: left;
    counter-reset: dots;
    position: relative;
    overflow-x: hidden;
    padding-top: 70px;

    li {
      height: auto;
      width: auto;
      padding: 0;
      margin: 0;
      position: static;

      &.slick-active {
        button {
          background: $primary-color;
          box-shadow: 0 0 0 0.5px $primary-color;
        }

        &:before {
          font-size: 50px;
          font-weight: 900;
        }
      }

      &:before {
        counter-increment: dots;
        content: counter(dots);
        font-size: 0;
        position: absolute;
        left: -8px;
        top: 0px;
        letter-spacing: 4px;
      }

      &:nth-child(-n+9) {
        &:before {
          content: "0" counter(dots);
          left: -15px;
        }
      }
    }

    button {
      height: 1px;
      background: $white;
      padding: 0;

      &:before {
        display: none;
      }
    }
  }

  .views-field-field-image img {
    border-radius: 50%;
    max-width: 150px;
    margin: auto;
  }

  .views-field-field-signature img {
    max-width: 150px;
    margin: auto;
  }

  h2 {
    color: $white;
    font-weight: 700;

    &:after {
      content:"";
      display: block;
      background: $white;
      width: 42px;
      height: 4px;
      margin: 5px auto;
    }
  }

  p {
    font-family: $font-family-alt;
    font-style: italic;
  }
}

.view-clients {
  .views-exposed-form {
    @include rem(padding-top, 50px);

    @include xssm {
      @include rem(padding-top, 20px);
    }
  }

  .views-widget-filter-title {
    display: block;

    @include smmdlg {
      width: 30%;
      margin-left: auto;
    }

    input {
      @include rem(padding, 10px 20px);
      border-radius: 30px;
      border: 1px solid $gray;
      background: none;
      color: $gray-light;
      font-weight: 900;
      text-align: center;
      font-size: 21px;
    }
  }

  .views-widget-filter-field_tag_tid,
  .views-widget-filter-field_tags_tid,
  .form-item-field-tags-tid-selective {
    .bef-select-as-links {
      > .form-item {
        display: flex;
        justify-content: flex-end;

        @include xssm {
          display: none;
          justify-content: center;
          margin-bottom: 20px;
        }

        > .form-item {
          display: flex;
          align-items: center;

          &::before {
            display: block;
            content:"";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: $white;
            margin: 0 10px;
          }

          &:first-child {
            &:before {
              display: none;
            }
          }
        }
      }

      a {
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 1px;

        &.active {
          color: $primary-color;
          font-weight: 700;
        }
      }
    }
  }

  .views-submit-button {
    display: none;
  }

  .view-content {
    @include rem(margin, 40px 0);
    display: flex;
    flex-wrap: wrap;
    font-weight: 700;
  }

  .views-row {
    flex: 0 0 calc(33.333% - 30px);

    @include xssm {
      flex: 0 0 100%;
      margin-bottom: 20px;
    }

    &:nth-child(3n+2) {
      margin: 0 45px 20px;

      @include xssm {
        margin: 0 0 20px 0;
      }
    }

    &:hover {
      .views-field-title {
        a {
          color: $primary-color;
        }

        &:after {
          background: $primary-color;
        }
      }
    }
  }

  .views-field-title {
    display: flex;
    align-items: center;

    &:after {
      content:"";
      display: block;
      background: $white;
      height: 3px;
      flex: 1 1 auto;
      margin-left: 10px;
    }
  }
}

.node-type-stories {
  #content-to-resize {
    align-items: flex-start;
  }

  #block-system-main {
    width: calc(60% - 20px);
    margin-right: 0;
    margin-left: auto;

    @include xssm {
      width: 100%;
    }
  }

  #main {
    > .container {
      width: 100%;
      max-width: none;
    }
  }

  .field-name-field-images {
    a {
      display: block;

      .views-field-title {
        a {
          pointer-events: none;

          &:hover {
              color: $white;
          }
        }
      }
    }

    img {
      width: 100%;
    }
  }

  .node-stories {
    .views-field-title {
      //position: absolute;
      //bottom: 20px;
      //right: 20px;
      text-align: left;
      font-size: 14px;
      margin-bottom: 20px;
      padding: 10px;

      h1 {
        margin: 0;
        color: $primary-color;
        font-size: font-size(h2);
      }

      .date {
        font-size: 14px;

        &:before {
          content:"-";
          margin-right: 5px;
        }

        &:after {
          display: block;
          content:"";
          width: 30%;
          background-color: $white;
          height: 1px;
          margin: 15px 0 30px;
        }
      }
    }
  }

  #block-views-stories-block-1 {
    .views-field-field-cover-verticale {
      a {
        padding: 0;
      }
    }
  }

  // #block-views-stories-block-1 {
  //   width: calc((100% - 20% - 20px - 60px) /100 * 40);
  //   position: fixed;
  //   top: 60px;
  //   bottom: 0;
  //   left: calc(20% + 20px);
  //   overflow-x: hidden;
  //
  //   @include xssm {
  //     width: 100%;
  //     position: relative;
  //     top: auto;
  //     bottom: auto;
  //     left: auto;
  //     margin-bottom: 15px;
  //   }
  //
  //   .views-field-field-image {
  //     height: 100%;
  //
  //     @include xssm {
  //       height: auto;
  //     }
  //   }
  //
  //   img {
  //     position: absolute;
  //     height: 100%;
  //     max-width: none;
  //     width: auto;
  //     top: 0;
  //     left: 50%;
  //     transform: translateX(-50%);
  //
  //     @include xssm {
  //       position: relative;
  //       height: auto;
  //       max-width: 100%;
  //       left: auto;
  //       transform: none;
  //     }
  //   }
  // }

  &.logged-in {
    // #block-views-stories-block-1 {
    //   top: 155px;
    //
    //   @include xssm {
    //     top: auto;
    //   }
    // }
  }
}

.view-stories {
  .view-filters {
    @include rem(padding, 20px 0);

    @include xssm {
      @include rem(padding, 20px 0 0);
    }

    div.bef-select-as-radios {
      display: flex;
      justify-content: center;
    }

    input {
      display: none;
    }

    .form-item {
      display: flex;
      align-items: center;

      &:before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: $white;
        margin: 0 10px;
      }

      &:first-child {
        // display: none;

        &:before {
          display: none;
        }
      }

      // &:nth-child(2) {
      //   &:before {
      //     display: none;
      //   }
      // }

      label {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 300;

        &:hover,
        &:focus {
          color: $primary-color;
          cursor: pointer;
        }
      }

      input:checked + label {
        font-weight: 700;
        color: $primary-color;
      }
    }

    .taxonomy-mariage & {
      #edit-field-tags-tid-20 + label {
        font-weight: 700;
        color: $primary-color;
      }
    }

    .taxonomy-corporate--event & {
      #edit-field-tags-tid-22 + label {
        font-weight: 700;
        color: $primary-color;
      }
    }
  }

  &.view-display-id-block {
    .views-row {
      a {
        @include rem(padding, 20px 0);
        font-weight: 300;
        display: block;
        position: relative;
        cursor: pointer;

        &:before {
          content: '';
          display: block;
          height: 1px;
          width: 0;
          transition: all ease-in .2s;
          background: $primary-color;
          position: absolute;
          left: 0;
          top: 50%;
        }

        &:hover,
        &:focus,
        &.active {
          color: $white;
          font-weight: 700;

          &:before {
            width: 40px;
          }
        }
      }
    }
  }

  &.view-display-id-block {
    > .view-content {
      @include xssm {
        @include rem(padding-left, 20px);
        display: block;
        white-space: nowrap;
        overflow-y: scroll;
        text-align: left;
        animation: shake 1s cubic-bezier(.97,.10,.10,.97) both;
        animation-delay: 1s;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }

      .views-row {
        @include xssm {
          display: inline-block;
        }

        a {
          @include xssm {
            @include rem(padding, 20px 10px);

            &:hover,
            &:focus,
            &.active {
              color: $primary-color;

              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.page-galerie,
.node-type-page-galerie {

  #main {
    > .container {
      width: 100%;
      max-width: none;
    }
  }

  #maincontent {
    // @include xssm {
    //   margin-top: -64px;
    // }
  }

  .view-galerie {

    img {
      //@include rem(margin-bottom, 20px);
      width: 100%;

      @include xs {
        margin-bottom: 20px;
      }
    }
  }
}

.views-exposed-form {
  .views-exposed-widget {
    float: none;
    padding: 0;
  }
}

.views-exposed-widgets {
  margin-bottom: 0;

  .page-clients & {
    @include smmdlg {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.region-sidebar-first,
#block-views-exp-galerie-page,
#block-views-exp-galerie-page form,
#block-views-exp-galerie-page div {
  height: 100vh;

  @include xssm {
    height: auto;
  }
}

#block-views-exp-galerie-page {

  .bef-select-as-radios,
  .bef-checkboxes,
  .bef-select-as-links > .form-item {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include xssm {
      display: block;
      white-space: nowrap;
      overflow-x: scroll;
      animation: shake 1s cubic-bezier(.97,.10,.10,.97) both;
      animation-delay: 1s;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;

      &.has-submenu {
        padding-bottom: 64px;
      }

      &.no-anim {
        perspective: none;
        transform: none;
      }
    }
  }

  .bef-select-as-radios,
  .bef-checkboxes {
    @include xssm {
      padding-left: 20px;
    }

    .form-item {
      height: auto;

      @include xssm {
        display: inline-block;
      }
    }
  }

  .form-item > .form-item {
    height: auto;

    @include xssm {
      display: inline-block;
    }
  }

  .form-item {
    input {
      display: none;

      &:checked {
        + label {
          color: $white;
          font-weight: 700;

          @include xssm {
            color: $primary-color;
          }

          &:before {
            width: 40px;

            @include xssm {
              display: none;
            }
          }
        }
      }
    }

    .bef-select-as-links {
      @include xssm {
        padding-left: 20px;
      }

      a {
        @include rem(padding, 20px 0);
        font-weight: 300;
        display: block;
        position: relative;
        cursor: pointer;

        @include xssm {
          @include rem(padding, 20px 10px);
          display: inline-block;
        }

        &:before {
          content: '';
          display: block;
          height: 1px;
          width: 0;
          transition: all ease-in .2s;
          background: $primary-color;
          position: absolute;
          left: 0;
          top: 50%;

          @include xssm {
            display: none;
          }
        }

        &:hover,
        &:focus,
        &.active {
          color: $white;
          font-weight: 700;

          @include xssm {
            color: $primary-color;
          }

          &:before {
            width: 40px;

            @include xssm {
              display: none;
            }
          }
        }
      }
    }

    label {
      @include rem(padding, 20px 0);
      font-weight: 300;
      display: block;
      position: relative;
      cursor: pointer;

      @include xssm {
        @include rem(padding, 20px 10px);
        display: inline-block;
      }

      &:before {
        content: '';
        display: block;
        height: 1px;
        width: 0;
        transition: all ease-in .2s;
        background: $primary-color;
        position: absolute;
        left: 0;
        top: 50%;

        @include xssm {
          display: none;
        }
      }

      &:hover,
      &:focus {
        color: $white;
        font-weight: 700;

        &:before {
          width: 40px;

          @include xssm {
            display: none;
          }
        }
      }
    }

    &.form-item-edit-field-tags-tid-all {
      display: none;
    }
  }
}

.captcha {
  border: none;
  padding: 0;
  max-width: 100%;

  .fieldset-description,
  legend {
    display: none;
  }

  > * {
    max-width: 100%;
  }
}

#block-views-contact-block-4 {
  display: none;

  @include xssm {
    display: block;
  }

  .view-contact {
    a {
      height: 60px;
      vertical-align: middle;
      font-size: 0;
      display: inline-block;
      width: 60px;
      text-align: center;

      &:before {
        @include custom-font('\EA07');
        font-size: 26px;
        line-height: 60px;
      }
    }
  }
}

@keyframes grow {
  0% {
      width: 0;
      left: 50%;
  }
  100%{
      width: 200px;
      left: calc(50% - 100px);
  }
}

.loader {
  background: $white;
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: bottom linear .3s, opacity ease-in-out .3s .15s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;

  &:before {
    display: block;
    content:"";
    width: 130px;
    height: 130px;
    background: url('../images/loader.png') center center no-repeat;
    background-size: 100% auto;
    margin-bottom: 20px;

    @include xlg {
      width: 240px;
      height: 225px;
    }
  }

  &.hide {
    bottom: 100%;
    opacity: 0;
  }

  .line {
    background: $gray-light;
    height: 2px;
    width: 200px;

    &:before {
      content:'';
      background: $primary-color;
      position: relative;
      height: 2px;
      animation:grow 1s .8s forwards;
      display: block;
      left: 50%;
      width: 0;
    }
  }
}

#block-views-temoignages-block-1 {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include xs {
    @include rem(margin-top, 70px);
    height: auto;
  }

  img {
    max-height: 120px;
    width: auto;
    margin: auto;
  }

  h2 {
    color: $white;
    font-weight: 900;
    text-align: center;
    font-size: 38px;
    margin-top: 0;

    &:after {
      content:"";
      display: block;
      height: 1px;
      background: $white;
      margin: 20px auto;
      width: 70px;
    }
  }

  .view-content {
    @include rem(padding, 0px 0 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .views-field-field-image {
    position: relative;
  }

  .hover {
    position: absolute;
    top: 0;
    opacity: 0;
    transition: all ease-in .3s;
    width: 100%;
  }

  .views-row {
    @include rem(margin, 20px);
    width: 12%;

    @include xs {
      width: 30%;
    }
  }

  a {
    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }
}
