@keyframes beat {
    0% {
        transform: scale(10);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.block.node3 {
  #node-3 {
    .field-name-body {
      a {
        animation: no-anim;
        opacity: 1;
      }
    }
  }
}

#node-3 {

  .field-name-body {
    p {
      color: $primary-color;
      font-size: 32px;
      font-weight: bold;
      line-height: 1.2;

      @include xlg {
        font-size: 40px;
      }

      @include xssm {
        text-align: center;
        font-size: 24px;
      }

      @include xs {
        font-size: 6vw;
      }
    }

    a {
      color: $primary-color;
    }

    em {
      font-size: 18px;
      color: $primary-color;
      display: inline-block;
      font-style: normal;
      font-weight: 300;
      text-transform: lowercase;

      @include smmdlg {
        animation: beat 0.2s 1 both;
        animation-delay: 3s;
        transform-origin: center;
        opacity: 0;
      }

      &:before {
        content: "+";
        font-weight: bold;
        vertical-align: middle;
        margin-right: 15px;
      }

      &:hover,
      &:focus {
        color: $white;

        &:before {
          color: $primary-color;
        }
      }
    }
  }
}

.hover {
  display: block;
  font-weight: bold;
  text-shadow: 40px 30px 20px rgba($primary-color, 0.4), -5px -5px 0 $primary-color;
  color: transparent;
  font-size: 100px;
  text-transform: capitalize;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.75px;
  -webkit-text-stroke-color: $white;
  margin-bottom: 20px;

  @include xlg {
    font-size: 115px;
    margin-bottom: 10px;
  }

  .i18n-en & {
    @include xs {
      font-size: 11vw;
      margin-top: 20px;
    }
  }

  @include xs {
    font-size: 25vw;
  }
}

.block-state {
  flex: 0 0 100%;
}

.cd-main {
  transition: all ease-in .5s;
  opacity: 0;
  flex: 0 0 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;

  &#maincontent {
    .region-content {
      flex: 0 0 100%;
    }
  }

  &.accueil,
  .front & {
    @include xs {
      align-items: flex-start;
    }
  }

  // &.overflow-hidden {
  //   position: absolute;
  //   top: 50%;
  //   transform: translate(100%, -50%);
  // }

  &.node4 {
    transform: translateX(100%);
  }

  &.node3 {
    transform: translateX(-100%);
  }

  // &:not(.visible) {
  //   position: absolute;
  // }

  .ajax-loaded & {
  }

  &.visible {
    transform: translateX(0);
    top: auto;
    opacity: 1;
  }


}

#node-4 {
  width: 50%;
  margin-left: 50%;

  @include xssm {
    width: 100%;
    margin-left: 0;
  }
}

#block-views-intro-block {
  width: 50%;
  margin-left: 50%;

  @include xssm {
    width: 100%;
    margin-left: 0;
  }

  h3 {
    font-size: 18px;
    margin: 0;
    display: flex;
    align-items: center;

    &:before {
      height: 2px;
      background: $primary-color;
      width: 40px;
      display: block;
      content:"";
      margin-right: 10px;
    }
  }
}

.region-sidebar-first {
  .node-type-stories & {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.page-temoignages {
  #main {
    .container {
      width: 90%;
      max-width: none;
    }
  }
}
