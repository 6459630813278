@font-face {
  font-family: 'icofont';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/icofont.woff') format('woff'),
       url('../fonts/icofont.woff2') format('woff2');
}

.icon[class^='icon-'],
.icon[class*=' icon-'] {
  display: inline-block;
  font-family: 'icofont';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}

$icons: (
  arrow: '\EA01',
		cross: '\EA02',
		email: '\EA03',
		facebook: '\EA04',
		instagram: '\EA05',
		linkedin: '\EA06',
		lock: '\EA07'
);

@each $name, $icon in $icons {
  .icon-#{$name}::before {
    content: $icon;
  }
}